import React, { useState, useCallback, useEffect, useContext } from 'react'
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import {Box, Button, Checkbox, FormControlLabel, Grid, Paper, Stack,TextField,Tooltip,Typography,Zoom,useMediaQuery} from "@mui/material";
import * as yup from "yup";
import { ApiService } from 'app/servicesTwo/ApiService';
import { useParams } from "react-router-dom";

import VolunteerInterestedHistoryItem from './VolunteerInterestedHistoryItem';
import MUIDataTable from 'mui-datatables';
import { LinkStyle } from 'app/pages/app/volunteersInterested/VolunteerInterestedItem';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useLocation } from 'react-router-dom';
import { PermissionContext } from 'app/contexts/PermissionContext';
import moment from 'moment';
import VolunteerAlocationFormResume from 'app/components/VolunteerAlocationFormResume/VolunteerAlocationFormResume';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import Div from '@jumbo/shared/Div';
import styled from '@emotion/styled';
import InfoIcon from '@mui/icons-material/Info';

const validationSchema = yup.object().shape({
    // status:
    // observacao:
    // turma_id:
    // turma_id_anterior:
    // pessoa_id:
});

const FileListItem = styled.li`
  list-style: none;
  /* margin: 1rem 0; */
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.9rem;
  min-width: 15%;
`;

const ModalVolunteerInterestedHistory = ({openVolunteerConsult, handleCloseVolunteer, item }) => {
    const {theme} = useJumboTheme();
    const Swal = useSwalWrapper();
    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const layoutOptions = React.useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        },
    }), [theme]);

    
    const { id } = useParams()
    const { hasPermission } = useContext(PermissionContext)
    const location = useLocation();
    const getUsername = item?.User?.nome;

    const modalAlertDeleteAreaVolunteer = (id) => {
      console.log(id);
      Swal.fire({
        title: 'Tem certeza que deseja apagar?',
        text: 'Não será póssível reverter a ação!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim!',
        cancelButtonText: 'Não!',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          deleteSpiritualCenter(id);
        }
      });
    };

    const toast = (variant, message) => {
      Swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        title: message,
        icon: variant,
        didOpen: (toast) => {
          toast.style.zIndex = 10000;
        },
        showCloseButton: true,
      });
    };
    
    console.log(location.pathname)
    const [volunteerInterestedHistory, setVolunteerInterestedHistory] = useState([]);
    const [volunteerHistory, setVolunteerHistory] = useState([]);
    const [volunteerAreasHistory, setVolunteerAreasHistory] = useState([]);
    const [volunteerAreaSelected, setVolunteerAreaSelected] = useState('');
    const [newActivityVolunteerArea, setNewActivityVolunteerArea] = useState(false);
    /* /areas-com-voluntarios/area/areaId/voluntario/voluntarioId */
    const getVolunteerHistory = useCallback(async () => {
       if(id) {
           try {
               ApiService.get(`/pessoa/${id}`)
               .then((response) => {
                   console.log('historico', response.data.user[0])
                   setVolunteerInterestedHistory(response.data.user[0].Interesse_Voluntario);
                   setVolunteerHistory(response.data.user[0].Voluntario)
               })
               .catch((error) => {
                 if (error.response) {
                     console.log(error.response.data);
                     console.log(error.response.status);
                     console.log(error.response.headers);
                   } else if (error.request) {
                     console.log(error.request);
                   } else {
                     console.log('error', error.message);
                   }
               })
           } catch (err) {
             console.log(err);
           }
       } else {
        try {
            ApiService.get(`/pessoa/${item.pessoa_id}`)
            .then((response) => {
                console.log('historico', response.data.user[0])
                setVolunteerInterestedHistory(response.data.user[0].Interesse_Voluntario);
                setVolunteerHistory(response.data.user[0].Voluntario)
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
       }
    }, []);

    const getVolunteerAreasHistory = useCallback(async () => {
      try {
          ApiService.get(`/areas-com-voluntarios/${item.voluntario_id || item?.Voluntario[0]?.voluntario_id}`)
          .then((response) => {
            console.log('historico de areas com voluntarios', response.data)
            setVolunteerAreasHistory(response.data)
          })
          .catch((error) => {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('error', error.message);
              }
          })
      } catch (err) {
        console.log(err);
      }
    }, []);

    
    const deleteSpiritualCenter = useCallback(async (areaId) => {
      try {
        ApiService.delete(
          `/areas-com-voluntarios/area/${areaId}/voluntario/${item.voluntario_id}`
        )
          .then((response) => {
            toast('success', 'Removido com sucesso');
            getVolunteerAreasHistory();
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log('error', error.message);
            }
          });
      } catch (err) {
        console.log(err);
        toast('error', 'Ocorreu um erro');
      }
    }, []);

    useEffect(() => {
      if(id || item.pessoa_id) {
        getVolunteerHistory()
        getVolunteerAreasHistory()
      };
    }, []); 
    
    const getNames = () => {
      const names = volunteerHistory?.map(row => row?.Areas?.nome ? row?.Areas?.nome : 'Voluntário não foi alocado');
      return Array.from(new Set(names)); // Remove duplicates
    };


    const columns = [
      {
        name: 'Areas.nome',
        label: 'Nome',
        options: {
          filter: true,
          sort: false,
          customFilterListOptions: { render: v => v ? `${v}` : 'Voluntário não foi alocado' },
          filterOptions: {
              names: getNames(),
              logic: (value, filters) => {
                if (filters.includes('Voluntário não foi alocado') && (value === null || value === undefined)) {
                  return false; // Show row if the value is null or undefined and 'Voluntário não foi alocado' is selected
                }
                if (filters.length && !filters.includes(value)) {
                  return true; // Hide row if it doesn't match any of the selected filters
                }
                return false; // Show row if it matches the selected filters
              },
              renderValue: v => v ? v : 'Voluntário não foi alocado',
              fullWidth: true,
          },
          filterType: 'dropdown',
          setCellProps: () => ({
            width: '40%',
          }),
          customBodyRender: (value) => (
              <Typography variant={'body1'}>{value ?? 'Voluntário não foi alocado'}</Typography>
            ),
        },
      },
      {
        name: 'local',
        label: 'Local',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value) => (
            <Typography variant={'body1'}>{value ?? '-'}</Typography>
          ),
        },
      },
      {
        name: 'teve_vivencia_pratica',
        label: 'Realizou curso de Capacitação de Voluntários?',
        options: {
          filter: false,
          sort: false,
          display: `${location.pathname === '/app/listar-alocar-voluntariado' ? false : true}`,
          setCellHeaderProps: (value) => ({
            align: 'center',
            width: '50%'
          }),
          setCellProps: () => ({
            align: 'center',
            width: '50%'
          }),
          customBodyRender: (value, tableMeta, updateValue) => (
            <>
              <Typography variant={'body1'}>
                {value ? 'Sim' : 'Não'}
              </Typography>
            </>
          ),
        },
      },
      {
          name: 'status',
          label: 'Status',
          options: {
            filter: false,
            sort: false,
            setCellHeaderProps: (value) => ({
              align: 'center',
              width: '10%'
            }),
            setCellProps: () => ({
              align: 'center',
              width: '10%'
            }),
            customBodyRender: (value, tableMeta, updateValue) => (
              <>
                <Typography variant={'body1'}>
                  {value}
                </Typography>
              </>
            ),
          },
      },
      
      {
          name: 'data_inicio',
          label: 'Data de início',
          options: {
            filter: false,
            sort: false,
            display: `${location.pathname === '/app/listar-alocar-voluntariado' ? true : false}`,
            setCellHeaderProps: (value) => ({
              align: 'center',
              width: '15%'
            }),
            setCellProps: () => ({
              align: 'center',
              width: '15%'
            }),
            customBodyRender: (value, tableMeta, updateValue) => (
              <>
                <Typography variant={'body1'}>
                  {value ? moment(value).format('DD-MM-YYYY') : '-'}
                </Typography>
              </>
            ),
          },
      },
      {
          name: 'ficha',
          label: 'Ficha',
          options: {
            filter: false,
            sort: false,
            setCellHeaderProps: (value) => ({
              align: 'center',
              width: '1%'
            }),
            setCellProps: () => ({
              align: 'center',
              width: '10%'
            }),
            customBodyRender: (value, tableMeta, updateValue) => (
              <>
                <Typography variant={'body1'}>
                  {value ? 'Sim' : 'Não'}
                </Typography>
              </>
            ),
          },
      },
      {
          name: 'voluntario_id',
          label: 'Ação',
          options: {
            filter: false,
            sort: false,
            setCellHeaderProps: (value) => ({
              align: 'center',
              width: '5%'
            }),
            setCellProps: () => ({
              align: 'center',
              width: '5%'
            }),
            customBodyRender: (value, tableMeta, updateValue) => (
              <>
                  <Tooltip
                      title="Ir para este voluntariado"
                      TransitionComponent={Zoom}
                      arrow
                  >
                  <LinkStyle 
                      to={ location.pathname.includes('alocar') ? `/app/editar-alocar-voluntariado-resume/${value}` : `/app/editar-voluntariado-resume/${value}`}
                      state={{ backUrl: location.pathname }}
                      disabled={!hasPermission('Pessoas', 'deletar') ? true : false}
                  
                  >
                      <ArrowForwardIosIcon/>
                      
                  </LinkStyle>

                  </Tooltip>
              </>
            ),
          },
      },
    ];

    const newColumns = [
      {
        name: 'nome',
        label: 'Área',
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({
            width: '80%',
          }),
          customBodyRender: (value) => (
            <Typography variant={'body1'}>{value ?? 'Voluntário não foi alocado'}</Typography>
          ),
        },
      },
      {
        name: 'area_id',
        label: 'Selecionar',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => (
            <>
              <Button
                disableElevation
                variant={'contained'}
                size={'small'}
                color={'info'}
                //disabled={!hasPermission('Pessoas', 'deletar') ? true : false}
                onClick={() => setVolunteerAreaSelected(value)}
              >
                Selecionar
              </Button>
            </>
          ),
        },
      },
      {
        name: 'area_id',
        label: 'Deletar',
        options: {
          filter: false,
          sort: false,
          display:`${openVolunteerConsult ? false : true}`,
          customBodyRender: (value, tableMeta, updateValue) => (
            <>
              <Button
                disableElevation
                variant={'contained'}
                size={'small'}
                color={'secondary'}
                //disabled={!hasPermission('Pessoas', 'deletar') ? true : false}
                onClick={() => modalAlertDeleteAreaVolunteer(value)}
              >
                Deletar
              </Button>
            </>
          ),
        },
      },

    ]
    
    const options = {
      filterType: 'checkbox',
      selectableRows: 'none',
      search: false,
      download: false,
      print: false,
      sortFilterList: false,
      viewColumns: false, //
      filter: false,
      enableNestedDataAccess: '.',
      textLabels: {
        toolbar: {
          filterTable: "Filtrar por Área",
        },
        pagination: {
          next: 'Próxima Página',
          previous: 'Página Anterior',
          rowsPerPage: 'Itens por Página:',
          displayRows: 'de',
        },
        filter: {
          all: 'Todos',
          title: "Filtrar por Área",
          reset: "Resetar",
        },
      },
      setTableProps: () => {
        return {
          size: 'small',
        };
      },
    };

  return (
    <JumboContentLayout
        layoutOptions={layoutOptions}
    >
        {
            lg && (
                <Stack spacing={2} direction={"row"} sx={{mb: 3, mt: -2}}>
                </Stack>
            )
        }
        <Paper sx={{p: '40px', height: '500px'}}>
            <Box sx={{ height: '100%', overflowY: 'auto' }}>
                <Box display="flex" justifyContent="flex-end" alignItems="center" marginRight={'1rem'} gap={1}>
                {(volunteerAreaSelected || newActivityVolunteerArea) && (
                    <Button
                      size='small'
                      variant='contained'
                      color='success'
                      onClick={() => {
                        setVolunteerAreaSelected('');
                        setNewActivityVolunteerArea(false)
                        getVolunteerAreasHistory()
                      }}
                    >
                      Voltar
                    </Button>
                  )}
                    <Button size="small" variant="contained" onClick={handleCloseVolunteer}>
                      Fechar
                    </Button>
                </Box>
                <Typography variant={"h1"} style={{ textAlign: 'center', marginBottom: 30 }}>
                  {
                    openVolunteerConsult ? 'Histórico de Voluntariado' : 'Alocação de Voluntário'
                  }
                </Typography>
                <React.Fragment>
                  <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <Box display={'flex'} alignItems={'center'} gap={1}>
                      <Typography variant={"h4"}>
                        {
                          openVolunteerConsult ? 'Voluntário:' : 'Voluntário:'
                        }
                      </Typography>
                      <Typography variant={"h4"} fontWeight={'bold'}>
                        {getUsername || item.nome}
                      </Typography>
                    </Box>
                    <Box display={'flex'} alignItems={'center'} gap={1} pr={2}>
                      <Typography variant={"h4"}>
                        Voluntário desde:
                      </Typography>
                      <Typography variant={"h4"} fontWeight={'bold'}>
                        {item?.voluntario_desde 
                          ? item.voluntario_desde 
                          : item.Voluntario?.length > 0
                          ? item.Voluntario[0]?.voluntario_desde 
                          : '-'
                        }
                      </Typography>
                    </Box>
                  </Box>
                    {/* {volunteerHistory.length > 0 ?
                        volunteerHistory.map((data, key) => (
                            <VolunteerHistoryItem item={data} handleCloseVolunteer={handleCloseVolunteer}/>
                        ))
                    : 'Nenhum histórico encontrado'} */}
                    
                     
                    <Box
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      flexDirection={'column'}
                      marginY={2}
                    >
                      <Grid container spacing={3} alignContent={'center'}>
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            name='codigo_antigo_voluntariado'
                            label='Código do Voluntário (Sistema Antigo)'
                            value={item.codigo_antigo_voluntariado || (item?.Voluntario?.length > 0 && item?.Voluntario[0]?.codigo_antigo_voluntariado)}
                            disabled={true}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            name='codigo_voluntario_feesp'
                            label='Código do Voluntário (Sistema Novo)'
                            value={
                              item.codigo_voluntario_feesp || (item?.Voluntario?.length > 0 && item.Voluntario[0]?.codigo_voluntario_feesp)

                            }
                            disabled={true}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name='teve_vivencia_pratica'
                                checked={item.teve_vivencia_pratica || (item?.Voluntario?.length > 0 && item?.Voluntario[0]?.teve_vivencia_pratica)}
                                disabled={true}
                              />
                            }
                            label='Realizou curso de Capacitação de Voluntários?'
                            labelPlacement='end'
                            
                            disabled
                          />
                        </Grid>
                        <Grid item lg={12} xs={2}>
                          <Box
                            display={'flex'}
                            flexWrap={'wrap'}
                            gap={3}
                            flexDirection={'column'}
                            mb={3}
                          >
                            <Typography>
                              Declaração(ões)
                            </Typography>
                            {
                              (item?.Voluntario?.length > 0 && item.Voluntario?.[0]?.Ficha_Do_Voluntario)
                                ? item.Voluntario[0].Ficha_Do_Voluntario.map((file) => (
                                    <FileListItem className="file-item" key={file.nome}>
                                      <p>
                                        <a
                                          href={`${process.env.REACT_APP_API_KEY}/images/${file.nome}`}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          {file?.nome_label || file?.nome.replace(/^\d+_/, '')}
                                        </a>
                                      </p>
                                    </FileListItem>
                                  ))
                                : item?.Ficha_Do_Voluntario?.map((file) => (
                                    <FileListItem className="file-item" key={file.nome}>
                                      <p>
                                        <a
                                          href={`${process.env.REACT_APP_API_KEY}/images/${file.nome}`}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          {file?.nome_label || file?.nome.replace(/^\d+_/, '')}
                                        </a>
                                      </p>
                                    </FileListItem>
                                  ))
                            }
                            {
                             ( item?.Ficha_Do_Voluntario?.length === 0 || item.Voluntario?.[0]?.Ficha_Do_Voluntario?.length === 0) && (
                                <Box display={'flex'} alignItems={'center'} gap={1}>
                                  <InfoIcon color={'error'}/>
                                  <Typography margin={0} variant="h5" color={'error'}>Voluntário não possui ficha</Typography>
                                </Box>
                              )
                            }
                          </Box>
                        </Grid>
                        
                      </Grid>
                      {
                        (!volunteerAreaSelected && !newActivityVolunteerArea && !openVolunteerConsult) && (
                          <Button
                            variant='contained'
                            color='success'
                            onClick={() => setNewActivityVolunteerArea(true)}
                          >
                            Nova Atividade
                          </Button>
                        )
                      }
                    </Box>
                      
                    
                    {
                      (volunteerAreaSelected || newActivityVolunteerArea) && (
                        <VolunteerAlocationFormResume 
                          volunteerId={item?.voluntario_id || (item?.Voluntario?.length > 0 && item?.Voluntario[0]?.voluntario_id)} 
                          areaId={volunteerAreaSelected}
                          newActivityVolunteerArea={newActivityVolunteerArea}
                          volunteerAreasHistory={volunteerAreasHistory}
                          openVolunteerConsult={openVolunteerConsult}
                        />
                      )
                    }

                    {volunteerAreasHistory.length > 0 && !volunteerAreaSelected && !newActivityVolunteerArea ?
                        <MUIDataTable
                          data={volunteerAreasHistory}
                          columns={newColumns}
                          options={options}
                        />
                    : undefined}
                   
                {
                  (!volunteerAreaSelected && !newActivityVolunteerArea) && (
                    <>
                      <Typography variant={"h4"} style={{ textAlign: 'left', marginTop: 30 }}>
                          Histórico de interesse
                      </Typography>
                      {volunteerInterestedHistory.length > 0 ?
                          volunteerInterestedHistory.map((data, key) => (
                              <VolunteerInterestedHistoryItem item={data} handleCloseVolunteer={handleCloseVolunteer}/>
                          ))
                      : 'Nenhum histórico encontrado'}
                    </>
                  )
                }
                   
                </React.Fragment>
            </Box>
        </Paper>
    </JumboContentLayout>
  )
}

export default ModalVolunteerInterestedHistory