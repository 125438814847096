import React, {
  useState,
  useCallback,
  useEffect,
  Fragment,
  useContext,
} from 'react';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import PageHeader from '../../../layouts/shared/headers/PageHeader/PageHeader';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import {
  Grid,
  Paper,
  Stack,
  useMediaQuery,
  Typography,
  Button,
  Box,
  Pagination,
  CircularProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import { ApiService } from 'app/servicesTwo/ApiService';
import JumboSearch from '@jumbo/components/JumboSearch';
import { Link, useLocation } from 'react-router-dom';
import { PermissionContext } from 'app/contexts/PermissionContext';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import BookTeamItem from './BookTeamItem';

const BookTeamList = () => {
  const { theme } = useJumboTheme();
  const animatedComponents = makeAnimated();
  const location = useLocation();
  const Swal = useSwalWrapper();
  const lg = useMediaQuery(theme.breakpoints.down('lg'));
  const { hasPermission } = useContext(PermissionContext);

  const [books, setBooks] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [select1Value, setSelect1Value] = useState(null);
  const [page, setPage] = useState(1);
  const [per_page, setPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [booksDepartments, setBooksDepartments] = useState([]);

  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up('lg')]: {
            position: 'sticky',
            zIndex: 5,
            top: 96,
            minHeight: 'auto',
          },
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: 'flex-start',
        },
      },
    }),
    [theme]
  );

  const getBooks = useCallback(async (searchData = {}) => {
    setLoading(true);
    try {
      const response = await ApiService.get('/livros', {
        params: {
          departament: searchData?.select1Value?.value || null,
          orderBy: false,
          page: searchData.page,
          per_page: searchData.per_page
        },
      });
      setBooksDepartments(response.data?.booksDepartments);
      setBooks(response.data?.books);
      setCount(response.data?.total_pages);
    } catch (error) {
      console.error('Error fetching books:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const searchData = {
      page,
      per_page,
      select1Value,
    };
    getBooks(searchData);
  }, [page, getBooks]);

  useEffect(() => {
    Swal.close();
  }, [location.pathname, Swal]);

  const handleSelect1Change = (selected) => {
    setSelect1Value(selected.length === 0 ? null : selected);
  };

  const booksDepartmentsOptions = [
    { value: '', label: 'Sem seleção' },
    ...booksDepartments
      ?.map((item) => {
        if (item.departamento_id) {
          return {
            value: item?.departamento_id,
            label: item?.Departamento?.nome,
          };
        }
        return null;
      })
      .filter(Boolean),
  ];

  const handleSubmit = () => {
    const searchData = {
      page,
      per_page,
      select1Value,
    };
    setLoading(true);
    getBooks(searchData);
  };

  return (
    <JumboContentLayout
      header={
        <PageHeader title={'Inclusão de Colaboradores nos Livros (Equipe)'} />
      }
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={'row'} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Paper sx={{ p: '40px' }}>
        <Box
          display='flex'
          alignItems='center'
          gap='1rem'
          marginBottom='1rem'
          flexWrap='wrap'
        >
          <Select
            id='select-one'
            //isMulti
            options={booksDepartmentsOptions}
            value={select1Value}
            onChange={handleSelect1Change}
            components={animatedComponents}
            placeholder='Selecione departamento/trabalho'
            styles={{
              menu: (list) => ({
                ...list,
                zIndex: 3,
              }),
              control: (base) => ({
                ...base,
                width: '300px',
              }),
            }}
          />
          <Button variant={'contained'} onClick={handleSubmit}>
            Filtrar
          </Button>
        </Box>

        {!location.pathname.includes('inserir') && (
          <Box display='flex' justifyContent='center'>
            <Link
              to='/app/nova-equipe'
              style={{
                textDecoration: 'none',
                pointerEvents: !hasPermission('Livros (Equipe)', 'criar')
                  ? 'none'
                  : '',
              }}
              state={{ backUrl: location.pathname }}
            >
              <LoadingButton
                color='success'
                type='submit'
                variant='contained'
                size='large'
                disabled={!hasPermission('Livros (Equipe)', 'criar')}
                sx={{
                  maxWidth: { md: '200px', mt: '1rem' },
                  marginBottom: '2rem',
                }}
              >
                Nova equipe
              </LoadingButton>
            </Link>
          </Box>
        )}
        <Fragment>
          {loading ? (
            <Box display='flex' justifyContent='center'>
              <CircularProgress />
            </Box>
          ) : (
            <>
              {books &&
                books.map((user, key) => (
                  <BookTeamItem
                    item={user}
                    setBooks={(data) => setBooks([...data])}
                    books={books}
                    key={key}
                  />
                ))}
              {books && books.length === 0 && (
                <Typography>Sem resultados correspondentes</Typography>
              )}
            </>
          )}
        </Fragment>
        <Pagination
          sx={{ mt: 2 }}
          color='primary'
          count={count}
          page={page}
          onChange={(event, value) => setPage(value)}
        />
      </Paper>
    </JumboContentLayout>
  );
};

export default BookTeamList;
