import React, { useCallback, useState, useContext } from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import Avatar from '@mui/material/Avatar';
import {
  Accordion,
  Card,
  Tooltip,
  Typography,
  Zoom,
  Grid,
  Modal,
  Fade,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import Span from '@jumbo/shared/Span';
import HistoryIcon from '@mui/icons-material/History';
import { ApiService } from 'app/servicesTwo/ApiService';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import capitalize from '../../../utils/capitalize';
import formatDate from '../../../utils/formatDate';
import { Link, useNavigate } from 'react-router-dom';
import { TextService } from 'app/servicesTwo/TextService';
import { PermissionContext } from 'app/contexts/PermissionContext';
import { useLocation } from 'react-router-dom';
import ModalCertified from './ModalCertified';

const Item = styled(Span)(({ theme }) => ({
  padding: theme.spacing(0, 1),
}));

export const LinkStyle = styled(Link)`
  padding: 4px 10px;
  background: #7352c7;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  svg {
    color: white;
  }
`;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80em',
  p: 4,
};

const UserCertifiedItem = ({ item, setUsers, users }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const maxLengthText = 20;

  return (
    <Card sx={{ mb: 1 }}>
      <Accordion square sx={{ borderRadius: 2 }}>
        <AccordionSummary
          aria-controls='panel1bh-content'
          id='panel1bh-header'
          sx={{
            px: 3,
            flexDirection: 'row-reverse',

            '& .MuiAccordionSummary-content': {
              alignItems: 'center',

              '&.Mui-expanded': {
                margin: '12px 0',
              },
            },
            '.MuiAccordionSummary-expandIconWrapper': {
              borderRadius: 1,
              border: 1,
              color: 'text.secondary',
              borderColor: 'divider',
              transform: 'none',
              height: 28,
              width: 28,
              alignItems: 'center',
              justifyContent: 'center',
              mr: 1,

              '&.Mui-expanded': {
                transform: 'none',
                color: 'primary.main',
                borderColor: 'primary.main',
              },

              '& svg': {
                fontSize: '1.25rem',
              },
            },
          }}
        >
          <Grid container spacing={2} alignContent={'center'}>
            <Grid item lg={4} md={4} xs={8} sx={{ flexShrink: 0, px: 1 }}>
              <Typography
                fontSize={'12px'}
                variant={'h6'}
                color={'text.secondary'}
                mb={0.25}
              >
                {/* {item.nome_social === '' || item.nome_social === null ? 'Nome' : 'Nome Social'}
                 */}
                Nome
              </Typography>
              <Typography variant={'body1'}>
                {/* {item.nome_social === '' || item.nome_social === null ? item.nome : item.nome_social} */}
                {item?.nome}
              </Typography>
            </Grid>
            <Grid item lg={3} md={3} xs={4} sx={{ flexShrink: 0, px: 1 }}>
              <Typography
                fontSize={'12px'}
                variant={'h6'}
                color={'text.secondary'}
                mb={0.25}
              >
                Email
              </Typography>
              {item.email && (
                <Tooltip title={item.email} arrow TransitionComponent={Zoom}>
                  <Typography variant={'body1'}>
                    {TextService.limitText(item.email, maxLengthText)}
                  </Typography>
                </Tooltip>
              )}
            </Grid>
            <Grid
              item
              lg={2}
              md={2}
              xs={3}
              sx={{ flexShrink: 0, px: 1, display: 'none' }}
            >
              <Typography
                fontSize={'12px'}
                variant={'h6'}
                color={'text.secondary'}
                mb={0.25}
              >
                Celular
              </Typography>
              <Typography variant={'body1'}>{item.celular}</Typography>
            </Grid>
            <Grid item lg={3} md={3} xs={4} sx={{ flexShrink: 0, px: 1 }}>
              <Typography
                fontSize={'12px'}
                variant={'h6'}
                color={'text.secondary'}
                mb={0.25}
              >
                CPF
              </Typography>
              <Typography variant={'body1'}>{item.cpf}</Typography>
            </Grid>
            <Grid
              item
              lg={2}
              md={2}
              xs={4}
              sx={{ flexShrink: 0, px: 1, display: 'none' }}
            >
              <Typography
                fontSize={'12px'}
                variant={'h6'}
                color={'text.secondary'}
                mb={0.25}
              >
                Data de nascimento
              </Typography>
              <Typography variant={'body1'}>
                {formatDate(item.data_nascimento)}
              </Typography>
            </Grid>
            <Grid
              item
              lg={1}
              md={2}
              xs={3}
              sx={{ flexShrink: 0, px: 1, display: 'none' }}
            >
              <Typography
                fontSize={'12px'}
                variant={'h6'}
                color={'text.secondary'}
                mb={0.25}
              >
                Sexo
              </Typography>
              <Typography variant={'body1'}>
                {capitalize(item.genero)}
              </Typography>
            </Grid>
            <Grid
              item
              lg={2}
              md={2}
              xs={4}
              sx={{
                ml: 'auto',
                flexDirection: 'row',
                display: 'flex',
              }}
            >
              <Tooltip
                title='Consultar Histórico'
                TransitionComponent={Zoom}
                arrow
              >
                <Item sx={{ ml: 'auto' }}>
                  <Button variant='contained' size='small' onClick={handleOpen}>
                    <HistoryIcon />
                  </Button>
                </Item>
              </Tooltip>
            </Grid>
          </Grid>
        </AccordionSummary>
      </Accordion>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Div sx={style}>
            <ModalCertified
              user={item}
              username={item.nome}
              userId={item?.pessoa_id}
              handleClose={handleClose}
            />
          </Div>
        </Fade>
      </Modal>
    </Card>
  );
};
/* Todo item prop define */

export default UserCertifiedItem;
