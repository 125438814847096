import React, { useState, useCallback, useEffect, Fragment, useContext } from 'react'
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "../../../layouts/shared/headers/PageHeader/PageHeader";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import { Grid, Paper, Stack, useMediaQuery, Typography} from "@mui/material";
import { LoadingButton } from '@mui/lab';

import { ApiService } from 'app/servicesTwo/ApiService';
import CoursesIntroItem from './CoursesIntroItem';
import JumboSearch from "@jumbo/components/JumboSearch";
import { Link, useLocation } from 'react-router-dom';
import { PermissionContext } from 'app/contexts/PermissionContext';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';


const CoursesIntroList = () => {  
    const {theme} = useJumboTheme();
    const location = useLocation();
    const Swal = useSwalWrapper();
    
    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const layoutOptions = React.useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        },
    }), [theme]);

    const [classes, setClasses] = useState(null)
    const [searchTerm, setSearchTerm] = useState(null)
    const [loading, setLoading] = useState(false)

    const { hasPermission } = useContext(PermissionContext);

    useEffect(() => {
        if(searchTerm == undefined) return;

        if(searchTerm == '') {
            setLoading(true)
            searchClass(searchTerm)
            setLoading(false)
        }

        const delayDebounceFn = setTimeout(() => {
          setLoading(true)
          searchClass(searchTerm)
          setLoading(false)
        }, 1500)

        
        return () => clearTimeout(delayDebounceFn)
      }, [searchTerm])

    const getClasses = useCallback(async () => {
        try {
            ApiService.get('/cursos-introdutorios')
            .then((response) => {
              console.log(response.data)
              setClasses(response.data);
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
      }, []);

      const searchClass = useCallback(async (e) => {
        console.log('pesquisar', e)
        try {
            ApiService.get('/cursos-introdutorios', {params: {search: e}})
            .then((response) => {
              setClasses(response.data);
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
      }, []);

      useEffect(() => {
        getClasses();
      }, []);

      useEffect(() => {
        console.log('atualizou', classes)
      }, [setClasses, classes]);

      useEffect(() => {
        Swal.close();
      }, [location.pathname, Swal]);

  return (
    <JumboContentLayout
        header={
            <PageHeader
                title={"Setup de curso no ano"}
            />
        }
       
        layoutOptions={layoutOptions}
    >
        {
            lg && (
                <Stack spacing={2} direction={"row"} sx={{mb: 3, mt: -2}}>
                </Stack>
            )
        }
        <Paper sx={{p: '40px'}}>
                    <Grid item xs={12} sx={{textAlign: 'center'}}> 
                        <Link to="/app/novo-abrir-inscricoes" style={{ textDecoration:'none', pointerEvents: !hasPermission('Inscrições nos Cursos', 'criar') ? 'none' : '' }} state={{ backUrl: location.pathname }}>
                            <LoadingButton
                                color="success"
                                type="submit"
                                variant="contained"
                                size="large"
                                disabled={!hasPermission('Inscrições nos Cursos', 'criar')}
                                sx={{maxWidth: {md: '300px', mt: '1rem'}, marginBottom: '2rem'}}
                            >
                              Setup de Novo Curso
                            </LoadingButton>
                        </Link>
                    </Grid>
                    <Typography 
                        variant="h6" 
                        sx={{fontWeight: '500', marginBottom: '1rem', marginLeft: '1rem'}}
                    >
                      Busque por nome do curso ou ano.
                    </Typography>
                    <Grid item xs={1} sx={{textAlign: 'center'}}> 

                        <JumboSearch
                            onChange={(e) => setSearchTerm(e)}
                            placeholder="Digite o nome do curso ou ano aqui..."
                            sx={{
                                width: '480px',
                                marginBottom: '1rem'
                            }}
                        />
                    </Grid>
                
                <Fragment>
                    
                    {
                        
                        classes && classes.map((user, key) => (
                            <CoursesIntroItem item={user} setClasses={(data) => setClasses([...data])} classes={classes} key={key}/>
                        ))
                    }
                    {
                        classes && classes.length == 0 && <Typography>Sem resultados correspondentes</Typography>
                    }
                </Fragment>
        </Paper>
    </JumboContentLayout>
  )
}

export default CoursesIntroList