import React, { useState, useCallback, useEffect, useContext } from 'react';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import PageHeader from '../../../layouts/shared/headers/PageHeader/PageHeader';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import {
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ApiService } from 'app/servicesTwo/ApiService';
import { Link, useLocation } from 'react-router-dom';
import Div from '@jumbo/shared/Div';
import JumboSearch from '@jumbo/components/JumboSearch';
import { PermissionContext } from 'app/contexts/PermissionContext';
import makeAnimated from 'react-select/animated';
import { makeStyles } from '@mui/styles';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import DirectorItem from './DirectorItem';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px',
    width: '320px',
    maxHeight: '490px',
    overflow: 'hidden',
    border: '2px solid #000',
  },
  media: {
    width: '140px',
    height: '140px',
    borderRadius: '16px',
    objectFit: 'cover',
  },
  mediaLogo: {
    width: '150px',
    height: '150px',
    borderRadius: '16px',
    objectFit: 'cover',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const DirectorList = () => {
  const { theme } = useJumboTheme();
  const location = useLocation();
  const Swal = useSwalWrapper();
  const classes = useStyles();

  const lg = useMediaQuery(theme.breakpoints.down('lg'));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up('lg')]: {
            position: 'sticky',
            zIndex: 5,
            top: 96,
            minHeight: 'auto',
          },
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: 'flex-start',
        },
      },
    }),
    [theme]
  );

  const [users, setUsers] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const animatedComponents = makeAnimated();

  const { hasPermission } = useContext(PermissionContext);

  useEffect(() => {
    Swal.close();
  }, [location.pathname, Swal]);

  const getUsers = useCallback(async (searchData) => {
    try {
      ApiService.get('/diretores', { params: { searchData } })
        .then((response) => {
          console.log(response.data);
          setUsers(response.data.directors);
          setIsLoading(false);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    getUsers()
  }, []);


  const [searchTerm, setSearchTerm] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (searchTerm == undefined) return;

    if (searchTerm == '') {
      setLoading(true);
      searchUser(searchTerm);
      setLoading(false);
    }

    const delayDebounceFn = setTimeout(() => {
      setLoading(true);
      searchUser(searchTerm);
      setLoading(false);
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const searchUser = useCallback(async (e) => {
    console.log('pesquisar', e);
    try {
      ApiService.get('/diretores', { params: { search: e } })
        .then((response) => {
          setUsers(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <JumboContentLayout
      header={<PageHeader title={'Diretores'} />}
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={'row'} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Paper sx={{ p: '10px' }}>
        <Grid item xs={1} sx={{ textAlign: 'center' }}>
          <Link
            to='/app/novo-diretor'
            style={{
              textDecoration: 'none',
              pointerEvents: !hasPermission('Diretores', 'criar') ? 'none' : '',
            }}
            state={{ backUrl: location.pathname }}
          >
            <LoadingButton
              color='success'
              type='submit'
              variant='contained'
              size='large'
              disabled={!hasPermission('Diretores', 'criar') ? true : false}
              sx={{
                maxWidth: { md: '200px', mt: '1rem' },
                marginBottom: '2rem',
              }}
            >
              Novo Cadastro
            </LoadingButton>
          </Link>
        </Grid>
        <Typography
          variant='h6'
          sx={{ fontWeight: '500', marginBottom: '1rem', marginLeft: '1rem' }}
        >
          Busque por nome, email, celular e cpf.
        </Typography>
        <Div
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <JumboSearch
            onChange={(e) => setSearchTerm(e)}
            placeholder='Digite o nome, email, celular ou cpf aqui...'
            sx={{
              width: '440px',
              marginBottom: '1rem',
            }}
          />
        </Div>
        <>
          {users != null && users.length == 0 && (
            <Typography>Sem resultados correspondentes</Typography>
          )}

          {/* <Box display="flex" justifyContent="flex-end" width="100%" marginBottom="1rem">
                    <Tooltip
                        title="Gerar crachás selecionados"
                        TransitionComponent={Zoom}
                        arrow
                    >
                        <Button 
                            onClick={handleGenerateBatchCrachas}
                            variant='contained'
                            size='small'
                            color='info'
                            disabled={selectedUsers.length === 0}
                        >
                            Gerar Crachás
                        </Button>
                    </Tooltip>
                </Box> */}
          {users &&
            users.map((user, key) => (
              <DirectorItem
                item={user}
                setUsers={(data) => setUsers([...data])}
                users={users}
                key={key}
              />
            ))}

          {isLoading && <CircularProgress />}
        </>
      </Paper>
    </JumboContentLayout>
  );
};

export default DirectorList;
