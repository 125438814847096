import React, {useState} from 'react';
import {Box, Card, CardContent, FormControl, InputLabel, MenuItem, Typography} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Stack from "@mui/material/Stack";
import {Facebook, Google, Twitter} from "@mui/icons-material";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import {alpha} from "@mui/material/styles";
import Div from "@jumbo/shared/Div";
import {ASSET_IMAGES} from "../../utils/constants/paths";
import {getAssetPath} from "../../utils/appHelpers";
import { ApiService } from 'app/servicesTwo/ApiService';
import { Formik, Form } from 'formik';
import * as yup from "yup";
import { LoadingButton } from '@mui/lab';
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { useLocation, useNavigate } from 'react-router-dom';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import { cpf } from 'cpf-cnpj-validator'; 
import InputMask from 'react-input-mask';

const validationSchema = yup.object().shape({
    email: yup
        .string()
        .email('Insira um email válido')
        .when('resetPassword', {
            is: 'email',
            then: yup.string().required('Preenchimento obrigatório'),
        }),
    cpf: yup
        .string()
        .when('resetPassword', {
        is: 'cpf',
        then: yup
            .string()
            .required('Preenchimento obrigatório')
            .test({ 
                message: 'CPF inválido',
                test: (value) => cpf.isValid(value)
            })
        }),
    celular: yup
        .string()
        .when('resetPassword', {
            is: 'celular',
            then: yup
                .string()
                .required('Preenchimento obrigatório')
                .matches(
                    /^\+(\d{3})\(\d{2}\)\d{5}-\d{4}$/, // Permite +55 ou +055
                    'Número de celular inválido'
                ),
        }),
    resetPassword: yup.string().required('Selecione uma opção'),
});

const ForgotPassword = () => {

    const Swal = useSwalWrapper();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const linkInvalid = queryParams.get('linkInvalid') === 'true';
    const errorMessage = location.state && location.state.message;


    const toast = (variant, message, type = false) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
            showCloseButton: true, // botão de fechar
            closeButtonAriaLabel: 'Fechar',
        });
      
    };


    const handleSubmit = async (values, setSubmitting) => {
        await ApiService.post('/esqueci-senha/', {values})
        .then((res) => {
            console.log(res.data.message)
            toast('success', res.data.message)
        })
        .catch((err) => {
            console.log(err.response.data.error)
            toast('error', err.response.data.error)
        })
        setSubmitting(false)
    }

    return (
        <Div sx={{
            flex: 1,
            flexWrap: 'wrap',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            p: theme => theme.spacing(4),
        }}>
            <Card sx={{maxWidth: '100%', width: 370, mb: 4}}>
                <Div sx={{position: 'relative', height: '200px'}}>
                    <CardMedia
                        component="img"
                        alt="logo feesp"
                        height="200"
                        image={`/images/logos/logo.png`}
                    />
                    <Div sx={{
                        flex: 1,
                        inset: 0,
                        position: 'absolute',
                        display: 'flex',
                        flexDirection: 'column',
                        //alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: alpha('#0267a0', .65),
                        p: theme => theme.spacing(3),
                    }}>
                        <Typography
                            variant={"h2"}
                            sx={{
                                color: 'common.white',
                                fontSize: '1.5rem',
                                mb: 0
                            }}>
                            Esqueci a senha
                        </Typography>
                        <Typography
                            variant={'h3'}
                            sx={{
                                color: 'common.white',
                                fontSize: '1rem',
                                mb: 0
                            }}
                        >
                            Você receberá um email com um link de redefinição de senha. Basta clicar no link e seguir as instruções para criar uma nova senha. 
                        </Typography>
                    </Div>
                </Div>
                <CardContent>
                    {linkInvalid &&
                        <Div 
                            sx={{
                                background: 'red', 
                                display: 'flex', 
                                alignItems: 'center',
                                color: '#ffffff',
                                gap: '1rem',
                                padding: '0.5rem'
                            }}
                        >
                            <ErrorOutlineIcon/>
                            <span>
                                {errorMessage}
                            </span> 
                        </Div>
                    }
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Typography
                            variant="body1"
                            mb={1}
                        >
                           Escolha uma das opções abaixo para redefini-la.
                        </Typography>
                    </Box>

                    <Formik
                        validateOnChange={false}
                        validateOnBlur={false}
                        initialValues={{
                            email: '',
                            cpf: '',
                            celular: '+055',
                            resetPassword: '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(data, {setSubmitting}) => {
                            handleSubmit(data, setSubmitting);
                        }}
                        enableReinitialize
                    >
                        {({isSubmitting, handleChange, values, touched, errors, setFieldValue}) => (
                            <Form style={{textAlign: 'left'}} noValidate autoComplete='off'>
                                <Div sx={{mb: 3, mt: 1}}>
                                <FormControl fullWidth>
                                <InputLabel shrink id="resetPassword">Escolha uma opção para redefinir sua senha</InputLabel>
                                    <JumboSelectField
                                        labelId="resetPassword"
                                        id="resetPassword"
                                        name="resetPassword"
                                        label="Escolha uma opção para redefinir sua senha"
                                        notched
                                    >
                                        <MenuItem value={'email'}>Email</MenuItem>
                                        <MenuItem value={'celular'}>Celular</MenuItem>
                                        <MenuItem value={'cpf'}>CPF</MenuItem>

                                    </JumboSelectField>
                                </FormControl>
                                </Div>
                            
                                <Box mb={3} mt={1}>
                                    {values.resetPassword === 'email' && (
                                        <JumboTextField
                                            fullWidth
                                            id="email"
                                            name="email"
                                            label="Email"
                                        />
                                    )}
                                    {values.resetPassword === 'cpf' && (
                                        <InputMask
                                            mask="999.999.999-99"
                                            maskPlaceholder={null}
                                            value={values.cpf}
                                            onChange={(e) => setFieldValue('cpf', e.target.value)}
                                        >
                                            {(inputProps) => 
                                                <JumboTextField
                                                    {...inputProps}
                                                    id='cpf'
                                                    fullWidth
                                                    name="cpf"
                                                    label="CPF"
                                                    type="text"
                                                    sx={{ background: '#ffffff'}}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    error={touched.cpf && !!errors.cpf}
                                                    helperText={touched.cpf && errors.cpf}
                                                />
                                            }
                                        </InputMask>
                                    )}
                                    {console.log('values celular', values)}
                                    {values.resetPassword === 'celular' && (
                                        <InputMask
                                            mask={"+999(99)99999-9999"}
                                            maskPlaceholder={null}
                                            value={values.celular}
                                            onChange={(e) => setFieldValue('celular', e.target.value)}
                                        >
                                            {(inputProps) =>
                                                <JumboTextField
                                                    {...inputProps}
                                                    fullWidth
                                                    id="celular"
                                                    name="celular"
                                                    label="Celular (DDI, DDD e número)"
                                                    sx={{ background: '#ffffff'}}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={touched.celular && !!errors.celular}
                                                    helperText={touched.celular && errors.celular}
                                                />
                                            }
                                        </InputMask>
                                    )}
                                </Box>
                                
                                <LoadingButton
                                    color="info"
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{mb: 3}}
                                    loading={isSubmitting}
                                >
                                    Enviar
                                </LoadingButton>

                            </Form>
                        )}
                    </Formik>
                   {/*  <Box display="flex" flexDirection="column" alignItems="center">
                        <Typography
                            variant="body1"
                            mb={1}
                        >
                            Não lembra do seu email?
                        </Typography>
                        <Link
                            underline="none" 
                            href="#"
                        >
                            Redefina sua senha usando o celular ou CPF cadastrados.
                        </Link>
                    </Box> */}
                </CardContent>
            </Card>
        </Div>
    );
};

export default ForgotPassword;
