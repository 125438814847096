import React, { useCallback, useState, useContext } from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import Avatar from '@mui/material/Avatar';
import { Accordion, Card, Grid, Tooltip, Typography, Zoom } from '@mui/material';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import Span from '@jumbo/shared/Span';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { ApiService } from 'app/servicesTwo/ApiService';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import capitalize from '../../../utils/capitalize';
import formatDate from '../../../utils/formatDate';
import { Link, useLocation } from 'react-router-dom';
import { TextService } from 'app/servicesTwo/TextService';
import { PermissionContext } from 'app/contexts/PermissionContext';
import { EllipsisTooltip } from 'app/components/EllipsisTooltip/EllipsisTooltip';
import { Box } from '@mui/material';

const Item = styled(Span)(({ theme }) => ({
    padding: theme.spacing(0, 1),
}));

const LinkStyle = styled(Link)`
    padding: 4px 10px;
    background: #7352c7;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;

    svg {
        color: white;
    }
`;

const CourseItem = ({ item, setCourses, courses }) => {
    const { hasPermission } = useContext(PermissionContext);
    const location = useLocation();

    const Swal = useSwalWrapper();
    const toast = (variant, message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
            showCloseButton: true, // botão de fechar
            closeButtonAriaLabel: 'Fechar',
        });
    };

    const modalAlert = (id) => {
        if (item.turmasComEsteCurso > 0) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                html: `<strong style="color:red">Não é possível excluir o curso porque há ${item.turmasComEsteCurso} turmas associadas.`,
            });
        } else {
            Swal.fire({
                title: 'Tem certeza que deseja apagar?',
                text: 'Não será póssível reverter a ação!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sim!',
                cancelButtonText: 'Não!',
                reverseButtons: true,
            }).then((result) => {
                if (result.value) {
                    deleteCourse(id);
                }
            });
        }
    };

    function removeById(arr, id) {
        const objWithIdIndex = arr.findIndex((obj) => obj.curso_id === id);

        if (objWithIdIndex > -1) {
            arr.splice(objWithIdIndex, 1);
        }

        return arr;
    }

    const deleteCourse = useCallback(async (id) => {
        try {
            ApiService.delete(`/cursos/${id}`)
                .then((response) => {
                    toast('success', 'Removido com sucesso');
                    const updatedCoursesArray = removeById(courses, id);
                    setCourses(updatedCoursesArray);
                })
                .catch((error) => {
                    if (error.response) {
                        toast('error', error.response.data.error);
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
                });
        } catch (err) {
            console.log(err);
            toast('error', 'Ocorreu um erro');
        }
    }, []);

    const maxLengthText = 20;

    return (
        <Card sx={{ mb: 1, p: 1 }}>
            <Grid container spacing={2} alignContent={'center'}>
                <Grid item xs={12} lg={3}>
                    <Typography
                        fontSize={'12px'}
                        variant={'h6'}
                        color={'text.secondary'}
                        mb={0.25}
                    >
                        Curso
                    </Typography>
                    
                    <EllipsisTooltip text={item?.nome ?? '-'} />
                </Grid>

                <Grid item xs={12} lg={3}>
                    <Typography
                        fontSize={'12px'}
                        variant={'h6'}
                        color={'text.secondary'}
                        mb={0.25}
                    >
                        Departamento
                    </Typography>
                    
                    <EllipsisTooltip text= {item?.Departamento?.nome ?? '-'} />
                </Grid>

                <Grid item xs={12} lg={3}>
                    <Typography
                        fontSize={'12px'}
                        variant={'h6'}
                        color={'text.secondary'}
                        mb={0.25}
                    >
                        Área
                    </Typography>
                    
                    <EllipsisTooltip text= {item?.Departamento?.Areas?.nome ?? '-'} />
                </Grid>

                <Grid item xs={12} lg={1}>
                    <Typography
                        fontSize={'12px'}
                        variant={'h6'}
                        color={'text.secondary'}
                        mb={0.25}
                    >
                        Carga Horária
                    </Typography>
                    
                    <EllipsisTooltip text= {item?.carga_horaria ?? '-'} />
                </Grid>

                <Grid item xs={12} lg={2}>
                    <Box
                        display='flex'
                        justifyContent='center'
                        width='100%'
                    >
                            <Tooltip
                            title='Visualizar'
                            TransitionComponent={Zoom}
                            arrow
                        >
                            <Item>
                                <LinkStyle
                                    to={`/app/editar-curso/${item.curso_id}`}
                                    state={{ backUrl: location.pathname }}
                                >
                                    <VisibilityIcon />
                                </LinkStyle>
                            </Item>
                        </Tooltip>

                        <Tooltip
                            title='Excluir'
                            TransitionComponent={Zoom}
                            arrow
                        >
                            <Item>
                                <Button
                                    sx={{
                                        minWidth: 1,
                                        background:
                                            item?.turmasComEsteCurso > 0
                                                ? 'rgba(0, 0, 0, 0.12)'
                                                : '',
                                    }}
                                    disableElevation
                                    variant={'contained'}
                                    size={'small'}
                                    color={'secondary'}
                                    disabled={
                                        !hasPermission('Cursos', 'deletar')
                                            ? true
                                            : false
                                    }
                                    onClick={() => modalAlert(item.curso_id)}
                                >
                                    <DeleteIcon />
                                </Button>
                            </Item>
                        </Tooltip>
                    </Box>
                    
                    
                </Grid>
            </Grid>
        </Card>
    );
};
/* Todo item prop define */

export default CourseItem;
