import React, { useContext } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Link from '@mui/material/Link';
import ListItemButton from '@mui/material/ListItemButton';
import { ListItemIcon, Tooltip } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import CircleIcon from '@mui/icons-material/Circle';
import useJumboLayoutSidebar from '@jumbo/hooks/useJumboLayoutSidebar';
import { SIDEBAR_VIEWS } from '@jumbo/utils/constants/layout';
import { useTranslation } from 'react-i18next';
import { PermissionContext } from 'app/contexts/PermissionContext';

const menuBefore = {
    left: 0,
    top: 0,
    content: `''`,
    position: 'absolute',
    display: 'inline-block',
    width: '4px',
    height: '100%',
    backgroundColor: 'transparent',
};

const JumboNavItem = ({ item, isNested, translate }) => {
    const location = useLocation();
    const { sidebarOptions } = useJumboLayoutSidebar();
    const { hasPermission } = useContext(PermissionContext);
    const { t } = useTranslation();

    const isMiniAndClosed = React.useMemo(() => {
        return (
            sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open
        );
    }, [sidebarOptions.view, sidebarOptions.open]);

    const label = React.useMemo(() => {
        //return translate ? t(item.label) : item.label;
        if (item.uri === '/app/listar-interesse-voluntariado') {
            item.label = 'Voluntariado';
            item.value = 'Interesse Voluntariado';

            return hasPermission(t(item.label), 'acessar')
                ? t(item.value)
                : null;
        }
        if (item.uri === '/app/listar-voluntariado') {
            item.label = 'Voluntariado';
            item.value = 'Voluntários';

            return hasPermission(t(item.label), 'acessar')
                ? t(item.value)
                : null;
        }
        if (item.uri === '/app/listar-alocar-voluntariado') {
            item.label = 'Voluntariado';
            item.value = 'Alocação de Voluntários';

            return hasPermission(t(item.label), 'acessar')
                ? t(item.value)
                : null;
        }
       /*  if (item.uri === '/app/listar-equipes') {
            item.label = 'Voluntariado';
            item.value = 'Equipe';

            return hasPermission(t(item.label), 'acessar')
                ? t(item.value)
                : null;
        }
        if (item.uri === '/app/listar-equipes-inserir-voluntario') {
            item.label = 'Voluntariado';
            item.value = 'Inclusão de Voluntários na Equipe';

            return hasPermission(t(item.label), 'acessar')
                ? t(item.value)
                : null;
        } */
        if (item.uri === '/app/impressao-de-crachas') {
            item.label = 'Voluntariado';
            item.value = 'Impressão de Crachás';

            return hasPermission(t(item.label), 'acessar')
                ? t(item.value)
                : null;
        }
        if (item.uri === '/app/listar-usuarios') {
            item.label = 'Pessoas';
            item.value = 'Cadastro Único';

            return hasPermission(t(item.label), 'acessar')
                ? t(item.value)
                : null;
        }
        if (item.uri === '/app/listar-associados') {
            item.label = 'Associados';
            item.value = 'Associados';

            return hasPermission(t(item.label), 'acessar')
                ? t(item.value)
                : null;
        }
        if (item.uri === '/app/listar-conselhos') {
            item.label = 'Conselho Deliberativo';
            item.value = 'Conselho Deliberativo';

            return hasPermission(t(item.label), 'acessar')
                ? t(item.value)
                : null;
        }

        return hasPermission(t(item.label), 'acessar') ? t(item.label) : null;
    }, [item, translate, t]);

    // console.log(location.pathname === item.uri)
    /* console.log(location, item) */

    if (!item) return null;

    const getActiveStyle = (item) => {
        const routeMappings = [
            //inicio sessão Admin
            {
                route: '/app/novo-diretor',
                label: 'Diretores'
            },
            {
                route: '/app/editar-diretor',
                label: 'Diretores'
            },
            {
                route: '/app/nova-sala',
                label: 'Salas'
            },
            {
                route: '/app/editar-sala',
                label: 'Salas'
            },
            {
                route: '/app/nova-area',
                label: 'Áreas',
            },
            {
                route: '/app/editar-area',
                label: 'Áreas',
            },
            {
                route: '/app/novo-departamento',
                label: 'Departamentos',
            },
            {
                route: '/app/editar-departamento',
                label: 'Departamentos',
            },
            {
                route: '/app/novo-perfil',
                label: 'Perfis',
            },
            {
                route: '/app/editar-perfil',
                label: 'Perfis',
            },
            {
                route: '/app/novo-usuario',
                label: 'Usuários',
            },
            {
                route: '/app/editar-usuario',
                label: 'Usuários',
            },
            {
                route: '/app/novo-abrir-inscricoes',
                label: 'Cursos Introdutórios',
            },
            {
                route: '/app/editar-abrir-inscricoes',
                label: 'Cursos Introdutórios',
            },
            //fim sessão Admin

            //inicio sessão Pessoas
            {
                route: '/app/novo-aluno',
                value: 'Cadastro Único',
            },
            {
                route: '/app/editar-aluno',
                value: 'Cadastro Único',
            },
            {
                route: '/app/novo-associado',
                value: 'Associados',
            },
            {
                route: '/app/editar-associado',
                value: 'Associados',
            },
            {
                route: '/app/novo-conselho',
                value: 'Conselho Deliberativo',
            },
            {
                route: '/app/editar-conselho',
                value: 'Conselho Deliberativo',
            },
            //fim sessão Pessoas

            //inicio sessão Ensino
            {
                route: '/app/novo-curso',
                label: 'Cursos',
            },
            {
                route: '/app/editar-curso',
                label: 'Cursos',
            },
            {
                route: '/app/nova-turma',
                label: 'Turmas',
            },
            {
                route: '/app/editar-turma',
                label: 'Turmas',
            },
            {
                route: '/app/listar-turmas-inserir-aluno',
                label: 'Inclusão de Alunos na Turma',
            },
            {
                route: '/app/inserir-aluno-turma',
                label: 'Inclusão de Alunos na Turma',
            },
            {
                route: '/app/novo-abrir-inscricoes',
                label: 'Inscrições nos Cursos',
            },
            {
                route: '/app/editar-abrir-inscricoes',
                label: 'Inscrições nos Cursos',
            },
            //fim sessão Ensino

            //inicio sessão Voluntariado
            {
                route: '/app/novo-voluntariado',
                value: 'Voluntários',
            },
            {
                route: '/app/editar-voluntariado',
                value: 'Voluntários',
            },
            {
                route: '/app/editar-alocar-voluntariado',
                value: 'Voluntários',
            },
            {
                route: '/app/nova-equipe',
                value: 'Livros (Equipe)',
            },
            {
                route: '/app/editar-equipe',
                value: 'Livros (Equipe)',
            },
            {
                route: '/app/inserir-voluntario-equipe',
                value: 'Inclusão de Voluntários na Equipe',
            },
            {
                route: '/app/novo-interesse-voluntariado',
                value: 'Interesse Voluntariado',
            },
            {
                route: '/app/editar-interesse-voluntariado-resume',
                value: 'Interesse Voluntariado',
            },
            {
                route: '/app/impressao-de-crachas',
                value: 'Impressão de Crachás',
            },
            //fim sessão Voluntariado
        ];

        const matchedRoute = routeMappings.find((mapping) => {
            //depois tentar validar com id por params em routeMappings
            if (item.value) {
                if (location.pathname.startsWith(mapping.route)) {
                    return item.value === mapping.value;
                }
                return (
                    location.pathname.includes(mapping.route) &&
                    item.value === mapping.value
                );
            } else {
                if (location.pathname.startsWith(mapping.route)) {
                    return item.label === mapping.label;
                }
                return (
                    location.pathname.includes(mapping.route) &&
                    item.label === mapping.label
                );
            }
        });

        if (matchedRoute) {
            return {
                color: (theme) => theme.palette.nav.action.active,
                backgroundColor: (theme) => theme.palette.nav.background.active,
            };
        }

        return {};
    };

    return (
        <>
            {label ? (
                <ListItemButton
                    component={'li'}
                    sx={{
                        p: 0,
                        overflow: 'hidden',
                        borderRadius: isMiniAndClosed ? '50%' : '0 24px 24px 0',
                        margin: isMiniAndClosed ? '0 auto' : '0',
                        ...(isMiniAndClosed
                            ? {
                                  width: 40,
                                  height: 40,
                                  justifyContent: 'center',
                              }
                            : {}),
                        ...(!isMiniAndClosed
                            ? { '&::before': menuBefore }
                            : {}),
                        '&:hover': {
                            color: (theme) => theme.palette.nav.action.hover,
                            backgroundColor: (theme) =>
                                theme.palette.nav.background.hover,
                            ...(!isMiniAndClosed
                                ? {
                                      '&::before': {
                                          ...menuBefore,
                                          backgroundColor: (theme) =>
                                              theme.palette.nav.tick.hover,
                                      },
                                  }
                                : {}),
                        },
                        ...(location.pathname === item.uri
                            ? {
                                  color: (theme) =>
                                      theme.palette.nav.action.active,
                                  backgroundColor: (theme) =>
                                      theme.palette.nav.background.active,
                                  ...(!isMiniAndClosed
                                      ? {
                                            '&::before': {
                                                ...menuBefore,
                                                backgroundColor: (theme) =>
                                                    theme.palette.nav.tick
                                                        .active,
                                            },
                                        }
                                      : {}),
                              }
                            : { ...getActiveStyle(item) }),
                    }}
                >
                    <Link
                        underline={'none'}
                        component={RouterLink}
                        to={item.uri}
                        {...(item.target ? { target: item.target } : {})}
                        sx={{
                            flex: 1,
                            display: 'flex',
                            alignItems: 'center',
                            overflow: 'hidden',
                            position: 'relative',
                            color: 'inherit',
                            p: (theme) =>
                                !isMiniAndClosed ? theme.spacing(1, 3.75) : 0,
                            ...(isMiniAndClosed
                                ? { justifyContent: 'center' }
                                : {}),
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: isMiniAndClosed ? 20 : 32,
                                color: 'inherit',
                            }}
                        >
                            {isNested ? (
                                <CircleIcon sx={{ fontSize: 6, ml: 1 }} />
                            ) : (
                                item.icon
                            )}
                        </ListItemIcon>
                        {!isMiniAndClosed && (
                            <Tooltip title={label} placement="right-start">
                                <ListItemText
                                    primary={label}
                                    sx={{
                                        m: 0,
                                        '& .MuiTypography-root': {
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            //fontSize: '1.05rem'
                                        },
                                    }}
                                />
                            </Tooltip>
                        )}
                    </Link>
                </ListItemButton>
            ) : null}
        </>
    );
};

export default JumboNavItem;
