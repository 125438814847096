import React, { useCallback, useEffect, useState } from 'react';
import { List } from '@mui/material';
import { useParams } from 'react-router-dom';
import { ApiService } from 'app/servicesTwo/ApiService';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import ListRowBookTeamItem from './ListRowBookTeamItem';

const ListRowBookTeam = ({
  data,
  updateVolunteerList,
  volunteersTeam,
  setSearchTerm,
  area_id,
  departamento_id,
  setIncludesVolunteerToTeam
}) => {
  const { id } = useParams();
  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
    });
  };

  const [itemsList, setItemsList] = useState(data);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [activities, setActivities] = useState([]);
  const [selectedActivities, setSelectedActivities] = useState({});

  const [inputsByVolunteer, setInputsByVolunteer] = useState({});

  const getAtividades = useCallback(async () => {
    try {
      ApiService.get(`/atividades-voluntarios`)
        .then((response) => {
          console.log(response.data);
          setActivities(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleActivitySelection = (volunteerId, activityId) => {
    setSelectedActivities((prev) => ({
      ...prev,
      [volunteerId]: activityId,
    }));
  };

  const handleInputChange = (volunteerId, fieldName, value) => {
    setInputsByVolunteer((prevInputs) => ({
      ...prevInputs,
      [volunteerId]: {
        ...prevInputs[volunteerId],
        [fieldName]: value,
      },
    }));
  };

  const includeVolunteerToTeam = useCallback(
    (record) => {
      console.log(record);
      console.log(selectedActivities);

      console.log(inputsByVolunteer);

      const selectedActivityId = selectedActivities[record.voluntario_id];
      const volunteerData = inputsByVolunteer[record.voluntario_id];
    
      if (!selectedActivityId) {
        toast(
          'error',
          'Selecione uma atividade antes de incluir o voluntário.'
        );
        return;
      }

      if (!volunteerData?.data_inicio) {
        toast('error', 'Preencha data de início.');
        return;
      }
      try {
        setIsButtonDisabled(true);
        ApiService.post(
          `/equipes/livro/${id}/voluntario/${record.voluntario_id}`,
          {
            atividade_voluntario_id: selectedActivityId,
            data_inicio: volunteerData?.data_inicio,
            data_fim: volunteerData?.data_fim ? volunteerData.data_fim : null,
            motivo_encerramento: volunteerData?.motivo_encerramento ? volunteerData.motivo_encerramento : null,
            departamento_id,
            area_id,
          }
        )
          .then((response) => {
            let newArray = volunteersTeam;
            newArray.push(response.data);
            updateVolunteerList(newArray);
            toast('success', 'Voluntário adicionado com sucesso');
            setSearchTerm('');
            setIsButtonDisabled(false);
          })
          .catch((error) => {
            if (error.response) {
              toast('error', error.response.data.error);
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log('error', error.message);
            }
          });
      } catch (err) {
        console.log(err);
      }
    },
    [selectedActivities, itemsList, inputsByVolunteer]
  );

  useEffect(() => {
    getAtividades();
  }, []);

  return (
    <List disablePadding>
      {data.map(
        (item, index) =>
          !item?.naTurma && (
            <ListRowBookTeamItem
              item={item}
              key={index}
              includeVolunteerToTeam={includeVolunteerToTeam}
              isButtonDisabled={isButtonDisabled}
              activities={activities}
              onActivitySelect={handleActivitySelection}
              inputs={inputsByVolunteer[item.voluntario_id] || {}} // Valores específicos por voluntário
              onInputChange={handleInputChange}
              setIncludesVolunteerToTeam={setIncludesVolunteerToTeam}
              setSearchTerm={setSearchTerm}
            />
          )
      )}
    </List>
  );
};

export default ListRowBookTeam;
