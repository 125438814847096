import React, { useState, useContext } from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import {
  Accordion,
  Card,
  Tooltip,
  Typography,
  Zoom,
  Grid,
  Chip,
  Box,
  Modal,
  Backdrop,
  Fade,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import VisibilityIcon from '@mui/icons-material/Visibility';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { Link, useNavigate } from 'react-router-dom';
import { PermissionContext } from 'app/contexts/PermissionContext';
import { useLocation } from 'react-router-dom';
import HistoryIcon from '@mui/icons-material/History';
import { Form, Formik } from 'formik';
import ModalVolunteerInterestedHistory from 'app/components/VolunteersInterested/Modals/ModalVolunteerInterestedHistory';
import { EllipsisTooltip } from 'app/components/EllipsisTooltip/EllipsisTooltip';

export const LinkStyle = styled(Link)`
  padding: 4px 10px;
  background: #7352c7;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  svg {
    color: white;
  }
`;

export const ButtonStyle = styled(Button)`
  padding: 4px 10px;
  min-width: 30px;
  background: #2eb5c9;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  svg {
    color: white;
  }
`;

const None = styled(Div)`
  @media (max-width: 1280px) {
    display: none;
  }
`;
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80em',
  p: 4,
};

const DirectorItem = ({
  item,
  setUsers,
  users,
}) => {
  const location = useLocation();
  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: 'Fechar',
    });
  };

  function removeById(arr, id) {
    const objWithIdIndex = arr.findIndex((obj) => obj.pessoa_id === id);

    if (objWithIdIndex > -1) {
      arr.splice(objWithIdIndex, 1);
    }

    return arr;
  }


  const { hasPermission } = useContext(PermissionContext);
  const navigate = useNavigate();


  return (
    <>
      <Card sx={{ mb: 1 }}>
        <Accordion square sx={{ borderRadius: 2 }}>
          <AccordionSummary
            aria-controls='panel1bh-content'
            id='panel1bh-header'
            sx={{
              px: 1,
              flexDirection: 'row-reverse',

              '& .MuiAccordionSummary-content': {
                alignItems: 'center',

                '&.Mui-expanded': {
                  margin: '12px 0',
                },
              },
              '.MuiAccordionSummary-expandIconWrapper': {
                borderRadius: 1,
                border: 1,
                color: 'text.secondary',
                borderColor: 'divider',
                transform: 'none',
                height: 28,
                width: 28,
                alignItems: 'center',
                justifyContent: 'center',
                mr: 1,

                '&.Mui-expanded': {
                  transform: 'none',
                  color: 'primary.main',
                  borderColor: 'primary.main',
                },

                '& svg': {
                  fontSize: '1.25rem',
                },
              },
            }}
          >
            <Grid container spacing={2} alignContent={'center'}>
              <Grid item lg={2} md={4} xs={8}>
                <Typography
                  fontSize={'12px'}
                  variant={'h6'}
                  color={'text.secondary'}
                  mb={0.25}
                >
                  Nome
                </Typography>
                <EllipsisTooltip text={item?.User?.nome} />
              </Grid>
              <Grid item lg={2} md={3} xs={4}>
                <Typography
                  fontSize={'12px'}
                  variant={'h6'}
                  color={'text.secondary'}
                  mb={0.25}
                >
                  Área
                </Typography>
                <EllipsisTooltip text={item?.Area?.nome ?? '-'} />
              </Grid>
              <Grid item lg={2} md={3} xs={4}>
                <Typography
                  fontSize={'12px'}
                  variant={'h6'}
                  color={'text.secondary'}
                  mb={0.25}
                >
                  CPF
                </Typography>
                <EllipsisTooltip text={item?.User?.cpf ?? '-'} />
              </Grid>
              <Grid item lg={2} md={3} xs={4}>
                <Typography
                  fontSize={'12px'}
                  variant={'h6'}
                  color={'text.secondary'}
                  mb={0.25}
                >
                  Email
                </Typography>
                <EllipsisTooltip text={item?.User?.email ?? '-'} />
              </Grid>
              <Grid item lg={2} md={3} xs={4}>
                <Typography
                  fontSize={'12px'}
                  variant={'h6'}
                  color={'text.secondary'}
                  mb={0.25}
                >
                  Celular
                </Typography>
                <EllipsisTooltip text={item?.User?.celular ?? '-'} />
              </Grid>

              <Grid item lg={2} md={2} xs={4}>
                <Box
                  display='flex'
                  justifyContent='center'
                  gap='1rem'
                  width='100%'
                >
                  <Tooltip
                    title=' Editar Diretor'
                    TransitionComponent={Zoom}
                    arrow
                  >
                    <LinkStyle
                      to={`/app/editar-diretor/${item.diretor_id}`}
                      state={{ backUrl: location.pathname }}
                      style={{
                        background: !hasPermission('Diretores', 'acessar')
                          ? 'rgba(0, 0, 0, 0.12)'
                          : '#0092D0',
                        pointerEvents: !hasPermission('Diretores', 'acessar')
                          ? 'none'
                          : '',
                      }}
                    >
                      <HistoryIcon
                        style={{
                          color: !hasPermission('Diretores', 'acessar')
                            ? 'rgba(0, 0, 0, 0.26)'
                            : '#ffffff',
                        }}
                      />
                    </LinkStyle>
                  </Tooltip>
                  <Tooltip title='Visualizar' TransitionComponent={Zoom} arrow>
                    <LinkStyle
                      to={`/app/editar-aluno/${item.User.pessoa_id}`}
                      state={{ backUrl: location.pathname }}
                      style={{
                        background: !hasPermission('Pessoas', 'acessar')
                          ? 'rgba(0, 0, 0, 0.12)'
                          : '#7352C7',
                        pointerEvents: !hasPermission('Pessoas', 'acessar')
                          ? 'none'
                          : '',
                      }}
                    >
                      <VisibilityIcon
                        style={{
                          color: !hasPermission('Pessoas', 'acessar')
                            ? 'rgba(0, 0, 0, 0.26)'
                            : '#ffffff',
                        }}
                      />
                    </LinkStyle>
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
          </AccordionSummary>
        </Accordion>
      </Card>
    </>
  );
};
/* Todo item prop define */

export default DirectorItem;
