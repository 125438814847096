import React, { useState, useCallback, useEffect, Fragment } from 'react';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import {
  Grid,
  Paper,
  Stack,
  useMediaQuery,
  Typography,
} from '@mui/material';
import { ApiService } from 'app/servicesTwo/ApiService';
import JumboSearch from '@jumbo/components/JumboSearch';
import { useParams } from 'react-router-dom';
import makeAnimated from 'react-select/animated';
import VolunteerTeamListItem from './VolunteerTeamListItem';

const VolunteerTeamList = ({
  volunteersTeam,
  setVolunteersTeam,
  handleInputFocus,
  handleInputBlur,
  getBookTeamsById
}) => {
  const { theme } = useJumboTheme();
  const { id } = useParams();
  const animatedComponents = makeAnimated();

  const lg = useMediaQuery(theme.breakpoints.down('lg'));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up('lg')]: {
            position: 'sticky',
            zIndex: 5,
            top: 96,
            minHeight: 'auto',
          },
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: 'flex-start',
        },
      },
    }),
    [theme]
  );

  const [allVolunteersTeams, setAllVolunteersTeams] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);
  const [getCourse, setGetCourse] = useState([]);

  useEffect(() => {
    console.log('searchTerm', searchTerm);
    if (searchTerm == undefined || searchTerm == '') {
        setVolunteersTeam(allVolunteersTeams);

      return;
    }

    const delayDebounceFn = setTimeout(() => {
      searchTeam(searchTerm);
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const getTeamVolunteer = useCallback(async (searchData) => {
    try {
      ApiService.get(`/equipes/${id}/voluntarios`, { params: { searchData } })
        .then((response) => {
          console.log('response.data', response.data);
          /* const volunteers = response.data.Curso_Historico.map((x) => ({
            ...x.User,
            status: x.status,
            curso_historico_id: x.curso_historico_id,
            observacao: x.observacao,
            motivo_reprovacao: x.motivo_reprovacao,
            ano_de_conclusao: x.ano_de_conclusao,
          })); */
          setAllVolunteersTeams(response.data);
          setVolunteersTeam(response.data);
          setGetCourse(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const searchTeam = useCallback(
    async (e) => {
      try {
        ApiService.get(`/equipes/${id}/voluntarios`, { params: { search: e } })
          .then((response) => {
            console.log('retorno', response.data);
            setVolunteersTeam(response.data);
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log('error', error.message);
            }
          });
      } catch (err) {
        console.log(err);
      }
    },
    [volunteersTeam]
  );

  useEffect(() => {
    getTeamVolunteer();
  }, []);




  return (
    <JumboContentLayout layoutOptions={layoutOptions}>
      {lg && (
        <Stack spacing={2} direction={'row'} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Paper sx={{ p: '10px' }}>
        <Grid container spacing={3}>
          <Grid item xs={6} sx={{ textAlign: 'center' }}>
            <JumboSearch
              onChange={(e) => setSearchTerm(e)}
              placeholder='Buscar por Nome, Email, CPF ou Status'
              sx={{
                width: '440px',
                marginBottom: '1rem',
              }}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
            />
          </Grid>
         
        </Grid>
        <Fragment>
          {volunteersTeam.length > 0 &&
            volunteersTeam
              .map((student, key) => (
                <VolunteerTeamListItem
                  item={student}
                  setVolunteersTeam={(data) => setVolunteersTeam([...data])}
                  volunteersTeam={volunteersTeam}
                  key={key}
                  getBookTeamsById={getBookTeamsById}
                  getTeamVolunteer={getTeamVolunteer}
                />
              ))}
          {volunteersTeam && volunteersTeam.length == 0 && (
            <Typography>Sem resultados correspondentes</Typography>
          )}
        </Fragment>
      </Paper>
    </JumboContentLayout>
  );
};

export default VolunteerTeamList;
