import React, { useState, useCallback, useEffect, useContext } from 'react';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import InputMask from 'react-input-mask';
import { ApiService } from 'app/servicesTwo/ApiService';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { updateInputValue } from '../../../utils/appHelpers';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Div from '@jumbo/shared/Div';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
/* import ClassStudentsList from "./ClassStudentsList"; */
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import { PermissionContext } from 'app/contexts/PermissionContext';
import ListRowVolunteerInterested from 'app/components/ListRowVolunteerInterested/ListRowVolunteerInterested';
import ProfileCard from 'app/components/ProfileCard/ProfileCard';
import JumboSearchClasses from '@jumbo/components/JumboSearchClasses';
import { useDebouncedCallback } from 'beautiful-react-hooks';
import styled from '@emotion/styled';
import FileListSlim from 'app/components/FileUploadSlim/FileListSlim';
import DeleteIcon from '@mui/icons-material/Delete';
import ListRowDirector from 'app/components/ListRowDirector/ListRowDirector';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const FileListItem = styled.li`
  list-style: none;
  /* margin: 1rem 0; */
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0.9rem;
`;

const DirectorForm = () => {
  const { theme } = useJumboTheme();
  const navigate = useNavigate();
  const Swal = useSwalWrapper();
  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: 'Fechar',
    });
  };

  const lg = useMediaQuery(theme.breakpoints.down('lg'));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up('lg')]: {
            position: 'sticky',
            zIndex: 5,
            top: 96,
            minHeight: 'auto',
          },
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: 'flex-start',
        },
      },
    }),
    [theme]
  );

  const initialValues = {
    diretor_id: '',
    pessoa_id: '',
    ano_inicio: '',
    ano_final: '',
    assinatura: '',
  };

  const validationSchema = yup.object().shape({
    //area_id: yup.number().required('Preenchimento obrigatório'),
    ano_inicio: yup.string().required('Preenchimento obrigatório'),
  });

  const { id } = useParams();

  const [searchTerm, setSearchTerm] = useState(null);
  const [loading, setLoading] = useState(false);

  const [users, setUsers] = useState(initialValues);
  const [addStudents, setAddStudents] = useState(null);
  const [classStudents, setClassStudents] = useState([]);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const [userFile, setUserFile] = useState(null);
  const [status, setStatus] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [userVolunteerCourse, setUserVolunteerCourse] = useState(false);
  const [username, setUsername] = useState('');

  const [areas, setAreas] = useState([]);

  const [signatureImage, setSignatureImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const [files, setFiles] = useState([]);
  const [filesSelected, setFilesSelected] = useState([]);
  const [isEditingFile, setIsEditingFile] = useState(false);
  const [textFile, setTextFile] = useState(null);

  const { hasPermission } = useContext(PermissionContext);
  let { state } = useLocation();

  const canCreate = hasPermission('Voluntariado', 'criar');
  const canEdit = hasPermission('Voluntariado', 'editar');
  const isDisabled = id ? !canEdit : !canCreate;

  const getCourseById = useCallback(async (voluntario_id) => {
    console.log(selectedOption);
    console.log(users);
    try {
      ApiService.get(`/diretores/${id || voluntario_id}`)
        .then((response) => {
          setUsers(response.data);
          setSelectedOption(response.data.User);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getAreas = useCallback(async () => {
    try {
      ApiService.get(`/areas`)
        .then((response) => {
          console.log(response.data);
          setAreas(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    if (id) {
      getCourseById(selectedOption, userVolunteerCourse);
    }
    getAreas();
  }, []);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    //return console.log('Submit', teste)

    let data = new FormData();
    data.append('assinatura', signatureImage);

    if (id) {
      //atualiza form sem encerrar o voluntariado

      await ApiService.put(`/diretores/${id}`, { values })
        .then((response) => {
          if (!signatureImage)
            toast('success', 'Diretor atualizado com sucesso');
          /* setSubmitClicked(true); */

          if (signatureImage) {
            ApiService.put(`/diretores/atualizar-imagem/${id}`, data)
              .then(() => {
                toast('success', 'Diretor atualizado com sucesso', true);
              })
              .catch((error) => {
                toast('error', 'Ocorreu um erro ao atualizar imagem', true);

                if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
              });
          }
        })
        .catch((error) => {
          toast('error', error.response.data.error);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } else {
      const allValues = {
        ...values,
        pessoa_id: username.pessoa_id,
      };

      await ApiService.post('/diretores', {
        values: allValues,
      })
        .then((response) => {
          toast('success', 'Criado com sucesso');
          setSubmitClicked(true);
          const directorId = response.data.diretor_id;
          if (signatureImage) {
            ApiService.put(`/diretores/atualizar-imagem/${directorId}`, data)
              .then(() => {})
              .catch((error) => {
                toast('error', 'Ocorreu um erro ao atualizar imagem', true);

                if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
              });
          }
        })
        .catch((error) => {
          toast('error', error.response.data.error);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    }

    setSubmitting(false);
  };

  const handleChangeImage = (e) => {
    setPreviewImage(URL.createObjectURL(e.target.files[0]));
    setSignatureImage(e.target.files[0]);
  };

  const modalAlertDeleteFile = (id, voluntario_id) => {
    console.log(id);
    Swal.fire({
      title: 'Tem certeza que deseja apagar?',
      text: 'Não será póssível reverter a ação!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        removeFileFromBd(id, voluntario_id);
      }
    });
  };

  const removeFileFromBd = async (id, voluntario_id) => {
    ApiService.delete(`/fichas-do-voluntario/${id}`)
      .then(() => {
        /* setFiles([]);
        setFilesSelected([]);
        setTextFile(null); */
        getCourseById(voluntario_id);
        toast('success', 'Removido com sucesso');
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('error', error.message);
        }
      });
  };

  useEffect(() => {
    if (searchTerm == undefined || searchTerm == '') {
      setAddStudents(null);
      return;
    }

    const delayDebounceFn = setTimeout(() => {
      searchStudent(searchTerm);
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const searchStudent = useCallback(async (e) => {
    setLoading(true);
    try {
      ApiService.get(`/pessoa`, { params: { search: e } })
        .then((response) => {
          setAddStudents(response.data);
          // toast('success', 'Aluno adicionado com sucesso');
          setLoading(false);
        })
        .catch((error) => {
          // toast('error', 'Ocorreu um erro');

          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleChangeSearch = useDebouncedCallback((event) => {
    setSearchTerm(event.target.value);
  });

  React.useEffect(() => {
    setSearchTerm(searchTerm);
  }, [searchTerm]);

  React.useEffect(() => {
    return () => handleChangeSearch.cancel();
  });

  const updateStudentList = useCallback(async (data) => {
    setClassStudents([...data]);
    setAddStudents(null);
  }, []);

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
  };

  const [submitClicked, setSubmitClicked] = useState(false);

  return (
    <JumboContentLayout
      header={
        <HeaderBreadcrumbs
          id={id}
          title={'Diretores'}
          subtitle={id ? 'Editar' : 'Cadastro de Diretores'}
          titleUrl={'/app/listar-diretores'}
        />
      }
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={'row'} sx={{ mb: 3, mt: -2 }}></Stack>
      )}

      <Paper sx={{ p: '40px' }}>
        {state && (
          <Button
            color='success'
            type='submit'
            variant='contained'
            size='large'
            sx={{ maxWidth: { md: '200px' }, mb: '2rem' }}
            onClick={() => navigate(state.backUrl)}
          >
            Voltar
          </Button>
        )}
        <Formik
          initialValues={users}
          validationSchema={validationSchema}
          enableReinitialize
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting, handleChange, setFieldValue }) => {
            return (
              <Form style={{ width: '100%' }} noValidate autoComplete='off'>
                <Grid container spacing={3} alignContent={'center'}>
                  {id ? null : (
                    <>
                      <Grid item xs={12}>
                        <JumboSearchClasses
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          placeholder='Busque por Nome, CPF ou E-mail.'
                          sx={{
                            width: '440px',
                            marginBottom: '1rem',
                          }}
                          onFocus={handleInputFocus}
                          onBlur={handleInputBlur}
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {loading && (
                          <Box display='flex' justifyContent='center'>
                            <CircularProgress />
                          </Box>
                        )}
                        {addStudents != null && addStudents.length > 0 && (
                          <ListRowDirector
                            data={addStudents}
                            updateStudentList={(data) =>
                              updateStudentList(data)
                            }
                            classStudents={classStudents}
                            status={status}
                            setStatus={setStatus}
                            setUsername={setUsername}
                            setSearchTerm={setSearchTerm}
                            setUsers={setUsers}
                            setPreviewFile={setPreviewFile}
                            setSubmitClicked={setSubmitClicked}
                          />
                        )}
                        {addStudents != null && addStudents.length == 0 && (
                          <Typography>
                            Sem resultados correspondentes
                          </Typography>
                        )}
                      </Grid>
                    </>
                  )}

                  <Grid item xs={6}>
                    <JumboTextField
                      fullWidth
                      name={id ? 'diretor_id' : 'pessoa_id'}
                      label='Nome'
                      value={
                        id
                          ? selectedOption && selectedOption.nome
                          : username && username.nome
                      }
                      disabled={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Autocomplete
                      fullWidth
                      id='area_id'
                      options={areas}
                      getOptionLabel={(option) => option.nome || ''}
                      value={
                        areas.find((area) => area.area_id === values.area_id) ||
                        null
                      }
                      onChange={(event, newValue) =>
                        setFieldValue(
                          'area_id',
                          newValue ? newValue.area_id : ''
                        )
                      }
                      autoHighlight={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label='Área'
                          variant='outlined'
                          InputLabelProps={{ shrink: true }}
                          sx={{ background: '#ffffff' }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <InputMask
                      mask='9999'
                      value={updateInputValue(users, values, 'ano_inicio')}
                      onChange={handleChange}
                      disabled={isDisabled}
                      disableUnderline={isDisabled}
                      placeholder='aaaa'
                    >
                      {(inputProps) => (
                        <JumboTextField
                          {...inputProps}
                          id='ano_inicio'
                          fullWidth
                          name='ano_inicio'
                          label='Ano de início'
                          type='text'
                          sx={{ background: '#ffffff' }}
                          disabled={inputProps.disableUnderline}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item xs={2}>
                    <InputMask
                      mask='9999'
                      value={updateInputValue(users, values, 'ano_final')}
                      onChange={handleChange}
                      disabled={isDisabled}
                      disableUnderline={isDisabled}
                      placeholder='aaaa'
                    >
                      {(inputProps) => (
                        <JumboTextField
                          {...inputProps}
                          id='ano_final'
                          fullWidth
                          name='ano_final'
                          label='Ano final'
                          type='text'
                          sx={{ background: '#ffffff' }}
                          disabled={inputProps.disableUnderline}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    xs={12}
                    sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}
                  >
                    {previewImage && (
                      <Avatar
                        alt='Assinatura'
                        src={previewImage}
                        sx={{ width: 56, height: 56 }}
                      >
                        A
                      </Avatar>
                    )}
                    {values.assinatura && !previewImage && (
                      <img
                        src={`${process.env.REACT_APP_API_KEY}/images/${values.assinatura}`}
                        alt='img'
                        style={{
                          width: 50,
                          height: 50,
                          borderRadius: 50,
                          marginRight: 10,
                        }}
                      />
                    )}
                    <Button
                      variant='contained'
                      component='label'
                      color='info'
                      sx={{ height: '53.13px', fontSize: { xs: '0.7rem' } }}
                      disabled={isDisabled}
                    >
                      Adicionar assinatura
                      <input type='file' hidden onChange={handleChangeImage} />
                    </Button>
                  </Grid>

                  <Grid item xs={12} sx={{ textAlign: 'center', mt: 3 }}>
                    <LoadingButton
                      color='success'
                      type='submit'
                      variant='contained'
                      size='large'
                      sx={{ maxWidth: { md: '200px', mt: '1rem' } }}
                      loading={isSubmitting}
                      disabled={isDisabled || isInputFocused || submitClicked}
                    >
                      {id ? 'Atualizar' : 'Cadastrar'}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Paper>
    </JumboContentLayout>
  );
};

export default DirectorForm;
