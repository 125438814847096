import React, { useState, useCallback, useEffect, useContext } from 'react';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import PageHeader from '../../../layouts/shared/headers/PageHeader/PageHeader';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import { Grid, Paper, Stack, useMediaQuery } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import { ApiService } from 'app/servicesTwo/ApiService';
import { Link, useLocation } from 'react-router-dom';
import { PermissionContext } from 'app/contexts/PermissionContext';
import ActivitiesItem from './ActivitiesItem';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';

const ActivitiesList = () => {
    const { theme } = useJumboTheme();
    const location = useLocation();
    const Swal = useSwalWrapper();

    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const layoutOptions = React.useMemo(
        () => ({
            sidebar: {
                sx: {
                    [theme.breakpoints.up('lg')]: {
                        position: 'sticky',
                        zIndex: 5,
                        top: 96,
                        minHeight: 'auto',
                    },
                    [theme.breakpoints.down('lg')]: {
                        display: 'none',
                    },
                },
            },
            wrapper: {
                sx: {
                    alignItems: 'flex-start',
                },
            },
        }),
        [theme]
    );

    const [activities, setActivities] = useState(null);
    const { hasPermission } = useContext(PermissionContext);

    const getActivities = useCallback(async () => {
        try {
            ApiService.get(`/atividades-voluntarios?orderByOrder=${true}`)
                .then((response) => {
                    setActivities(response.data);
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        getActivities();
    }, []);

    useEffect(() => {
        Swal.close();
    }, [location.pathname, Swal]);

    return (
        <JumboContentLayout
            header={<PageHeader title={'Atividades'} />}
            layoutOptions={layoutOptions}
        >
            {lg && (
                <Stack
                    spacing={2}
                    direction={'row'}
                    sx={{ mb: 3, mt: -2 }}
                ></Stack>
            )}
            <Paper sx={{ p: '40px' }}>
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <Link
                        to='/app/nova-atividade'
                        style={{
                            textDecoration: 'none',
                            pointerEvents: !hasPermission('Atividades', 'criar')
                                ? 'none'
                                : '',
                        }}
                        state={{ backUrl: location.pathname }}
                    >
                        <LoadingButton
                            color='success'
                            type='submit'
                            variant='contained'
                            size='large'
                            disabled={!hasPermission('Atividades', 'criar')}
                            sx={{
                                maxWidth: { md: '200px', mt: '1rem' },
                                marginBottom: '2rem',
                            }}
                        >
                            Nova Atividade
                        </LoadingButton>
                    </Link>
                </Grid>

                <React.Fragment>
                    {activities?.map((user, key) => (
                        <ActivitiesItem
                            item={user}
                            setActivities={(data) => setActivities([...data])}
                            activities={activities}
                            key={key}
                        />
                    ))}
                </React.Fragment>
            </Paper>
        </JumboContentLayout>
    );
};

export default ActivitiesList;
